<template>
  <div class="relative z-50">
    <!--    <img-->
    <!--      :src="src"-->
    <!--      :style="`height: ${height}px; width: ${width}px`"-->
    <!--    >-->
    <!--  -->
    <canvas 
      ref="croppedImage"
      :width="width*resolutionFactor"
      :height="height*resolutionFactor"
      :style="`height: ${height}px; width: ${width}px`"
    />

    <!--      <img-->
    <!--        :src="src"-->
    <!--        @load="onImageLoad"-->
    <!--      >-->
  </div>
</template>

<script>
import {ref, watch} from "vue";
export default {
  name: "PdfImageCropped",
  props: {
    src: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    }
  },
  setup(props) {
    const croppedSrc = ref();
    const resolutionFactor = 16;


    // const uncroppedImage = ref();
    const croppedImage = ref();



    let uncroppedImage;

    watch(() => props.src, () => {
      uncroppedImage = new Image();
      uncroppedImage.src = props.src;

      uncroppedImage.onload = function(){
        onImageLoad()
      }
    }, {immediate: true})

    const onImageLoad = () => {

      const originalWidth = uncroppedImage.width;
      const originalHeight = uncroppedImage.height;

      // if > 1, image is wider than high, if < 1, otherwise.
      const originalRatio = originalWidth / originalHeight;
      const originalRatioHeight = originalHeight / originalWidth;

      const targetRatioW = props.width / props.height;
      const targetRatioH = props.height / props.width;

      // We try to make the crop area as large as possible, so at least either width or height should be in original size.
      const cropWidth = originalRatio > 1 ? originalHeight * targetRatioW : originalWidth;
      const cropHeight = originalRatio < 1 ? originalWidth * targetRatioH : originalHeight;

      const insetLeft = originalRatio > 1 ? (originalWidth - cropWidth)/2 : 0;
      const insetTop = originalRatio < 1 ? (originalHeight - cropHeight)/2 : 0;

      // console.log("cropped",croppedImage.value);
      // console.log("uncropped",uncroppedImage);
      // console.log("original uncropped", originalWidth, originalHeight);
      // console.log("target ratio w h", targetRatioW, targetRatioH);
      // console.log("original ratio", originalRatio);
      // console.log("props width height", props.width, props.height);
      // console.log("crop width height", cropWidth, cropHeight);

      const ctx = croppedImage.value.getContext('2d');
      ctx.drawImage(uncroppedImage, insetLeft, insetTop, cropWidth, cropHeight, 0, 0, props.width*resolutionFactor, props.height*resolutionFactor);
    }
    
    return {
      croppedImage,
      uncroppedImage,
      croppedSrc,
      onImageLoad,
      resolutionFactor,
    }
  }
}
</script>

<style scoped>

</style>