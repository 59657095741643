import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a467c5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pdf-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_counter_start = _resolveComponent("page-counter-start")!
  const _component_mast_uebersicht = _resolveComponent("mast-uebersicht")!
  const _component_mast_meldung = _resolveComponent("mast-meldung")!
  const _component_mast_zugaenglichkeit = _resolveComponent("mast-zugaenglichkeit")!
  const _component_mast_notizen = _resolveComponent("mast-notizen")!
  const _component_page_counter_end = _resolveComponent("page-counter-end")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_page_counter_start),
    _createVNode(_component_mast_uebersicht, {
      ba: _ctx.ba,
      immobilie: _ctx.immobilie,
      meldungen: _ctx.meldungen,
      lang: _ctx.lang
    }, null, 8, ["ba", "immobilie", "meldungen", "lang"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.meldungen, (meldung, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (index != 0)
          ? (_openBlock(), _createBlock(_component_mast_meldung, {
              key: 0,
              meldung: meldung,
              immobilie: _ctx.immobilie,
              ba: _ctx.ba,
              lang: _ctx.lang,
              index: index
            }, null, 8, ["meldung", "immobilie", "ba", "lang", "index"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createVNode(_component_mast_zugaenglichkeit, {
      zugaenglichkeiten: _ctx.zugaenglichkeiten,
      immobilie: _ctx.immobilie,
      ba: _ctx.ba,
      lang: _ctx.lang
    }, null, 8, ["zugaenglichkeiten", "immobilie", "ba", "lang"]),
    _createVNode(_component_mast_notizen, {
      notizen: _ctx.notizen,
      immobilie: _ctx.immobilie,
      ba: _ctx.ba,
      lang: _ctx.lang
    }, null, 8, ["notizen", "immobilie", "ba", "lang"]),
    _createVNode(_component_page_counter_end)
  ]))
}