import { APhotoUploadTask, uploadPhoto } from "@/api/UploadApi";
import apiClient, { strapiApiUrl } from "@/api/api";
import useImmobilieHelper from "@/composables/Property/useImmobilieHelper";
import { SortTerm, SortTermActive } from "@/composables/Sort/SortTerms";
import { AppPlatform } from '@/composables/useAppInfo';
import useDownloadHelper from "@/composables/useDownloadHelper";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import { ImmobilieStatus } from "@/models/immobilie/interfaces/IImmobilie";
import { APhoto } from "@/models/photo/a-photo.model";
import { instanceOfPhoto } from "@/utilities/get-media-url";
import { sortArrayByProperty } from "@/utilities/sortArrayByProperty";
import { AppLauncher } from "@capacitor/app-launcher";
import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
import { generateUUID } from "@ionic/cli/lib/utils/uuid";
import { alertController, toastController } from '@ionic/vue';
import QueryString from 'qs';
import { computed } from "vue";
import { environment } from "../../../environments/environment";
import User from "@/models/user";

export function useProperties() {

    const store = useStore();
    const immoPhotoUid = generateUUID();
    const { user } = useUser()
    const currentProject = computed(() => store.state.user.currentUserProject);
    const featureFlags = computed(() => user.value!.organisation?.featureFlags)
    const { isMobile } = useDownloadHelper();
    const networkConnected = computed(() => store.state.app.networkConnected);
    /**
     * Properties from vuex. Some of them may be local as well. If we are offline, we only show downloaded properties
     * as we can only open fully downloaded properties anyway.
     */
    const properties = computed(() => {

        const isOffline = computed(() => { return store.getters["app/isOffline"]; });
        const persistedProps = computed<Immobilie[]>(() => { return Immobilie.getters('persistedProperties')})

        const sortBy = Immobilie.getters("sortBy");
        const statusFilter = Immobilie.getters("statusFilter");
        const personFilter = Immobilie.getters("personFilter");

        const searchFilter = (property: Immobilie) => {
            let searchTerm = Immobilie.getters('searchTerm');
            searchTerm = searchTerm && searchTerm.toLowerCase();

            if (!searchTerm) { return true; }
            return Immobilie.getters('propMatchesFilter')(property, searchTerm);
        }

        if (!isOffline.value) {
            const all = Immobilie.query().with("bestandsaufnahmes").get();
            const filteredSortedProperties = all
                .filter(el => !el.id.toString().includes('$'))
                .filter(property => matchesFilters(property))
                .filter(searchFilter)
                .sort((a,b) => sortArrayByProperty(a,b, getCurrentSortTermWithFunction(sortBy)));

            return filteredSortedProperties;
        }

        function matchesFilters(property: any) {
            const statusMatch = statusFilter.length === 0 || statusFilter.includes(property.status);
            const personMatch = personFilter.length === 0 || property.verwalters.some((person: any) => personFilter.includes(person.username));
        
            return statusMatch && personMatch;
        }

        const results = persistedProps.value ? persistedProps.value
            .filter(el => el.isDownloaded)
            .filter(el => !el.id.toString().includes('$'))
            .filter(searchFilter)
            .sort((a, b) => sortArrayByProperty(a,b, getCurrentSortTermWithFunction(sortBy))) : [];

        return results;
    });


    /**
     * Build the query for the backend.
     */
    const getNextQuery = async () => {
        const pageSize = 25;
        await Immobilie.dispatch('setPage', (Immobilie.getters("page") + 1));
        const sortBy = Immobilie.getters("sortBy") as SortTermActive;

        const searchString = Immobilie.getters("searchTerm");
        const statusFilter = Immobilie.getters("statusFilter");
        const personFilter = Immobilie.getters("personFilter");
        const organizationUsers = store.state.user.organizationUsers ?? [];

        const query: any = {
            pagination: {
                page: Immobilie.getters("page"),
                pageSize: pageSize
            }
        };

        if (sortBy) {
            const sortObj = { [sortBy.fieldName]: sortBy.orderBy }

            if (sortBy.subObject) {
                const splittedKeys = sortBy.subObject?.split('.');
                if (splittedKeys.length === 1) query.sort = { [sortBy.subObject]: sortObj}
                if (splittedKeys.length === 2) query.sort = { [splittedKeys[0]]: { [splittedKeys[1]]: sortObj } }

                console.log("query sort", query.sort);
            } else {
                query.sort = sortObj;
            }
        }

        if (!query.filters) { query.filters = { $and: [] } }

        if ((searchString ?? "") != "") {

            query.filters = { 
                $and: [{
                    $or: [
                        { name: { $containsi: searchString } },
                        { eigentuemer: { $containsi: searchString } },
                        { strasse: { $containsi: searchString } },
                        { plz: { $containsi: searchString } },
                        { stadt: { $containsi: searchString } },
                        { externeObjektNr: { $containsi: searchString } },
                        { baujahr: { $containsi: searchString } },
                    ]
                }]
            }
        }

        if (statusFilter.length > 0) {
            if (!query.filters) { 
                query.filters = { $and: [] } 
            }
            query.filters.$and.push({
                $or: statusFilter.map((status: ImmobilieStatus) =>
                    ({ status: status ? { $containsi: status } : { $null: true } }))
            });
        }

        if (personFilter.length > 0) {
            if (!query.filters) { 
                query.filters = { $and: [] } 
            }
            query.filters.$and.push({
                $or: personFilter
                    .map((person: string) => organizationUsers.find((user: User) => user.username === person)?.id ?? 0)
                    .filter((id: number) => id !== 0)
                    .map((id: number) => ({ verwalters: { id } }))
            });
        }

        return query;
    }


    /**
     * Stringifies the query
     */
    const getQueryString = async () => {
        return QueryString.stringify(await getNextQuery())
    }

    /**
     * Sortable items
     */
    const supportedSortTerms: SortTerm[] = [
        { label: 'Leitungsanlage', fieldName: 'stadt'},
        { label: 'Mastnummer', fieldName: 'name'},
        { label: 'Objekt Nr.', fieldName: 'externeObjektNr'}
    ];
    // When using supportedSortTerms in vue, the function gets lost (probably because the var gets parsed to string somehwere and then it's converted back)
    const getCurrentSortTermWithFunction = (currentSortTerm: SortTermActive): SortTermActive => {
        return { ...currentSortTerm, localSubobjectSortFunction: supportedSortTerms.find(el => el.label === currentSortTerm.label)?.localSubobjectSortFunction }
    }

    /**
     * Load Properties from IndexedDB but do not store them in vuex directly. We don't know if we want to show those properties at this state due to search/filter function;
     */
    const loadPersistedProperties = async (): Promise<Immobilie[]> => {
        await Immobilie.dispatch("loadPersistedProperties");
        return Immobilie.getters("persistedProperties")
    }

    /**
     * Sanitize immobilies after fetching from backend.
     */
    const sanitizePropertyAfterFetch = (prop: Immobilie) => {
        prop.bestandsaufnahmes?.forEach(ba => {
            ba.immobilie = prop.id;
        })
    }

    /**
     * Persist immobilie with id
     */
    const downloadImmobilie = async (id: number) => {

        const immobilie: Immobilie | undefined = Immobilie.find(id) as (Immobilie | undefined);

        if (immobilie) {
            immobilie.isDownloaded = true;
            await Immobilie.dispatch('$updateLocally', { data: immobilie });
            // await Immobilie.dispatch('addToPersistedProperties', immobilie);
        } else {
            console.error("downloadImmobilie: immobilie is empty and could not be downloaded.")
        }
    }

    /**
     * Fetch immobilies from backend
     * Called after login, when on /bas or /properties page
     */
    const loadImmobilienPreviews = async (query?: string) => {

        const persistedProperties = await loadPersistedProperties();
    
        Immobilie.commit((state) => {         
            state.searchResultsCount = null;
            state.totalSearchResultsCount = null;
        });

        try {
            if (!Immobilie.getters("sortBy")) { await Immobilie.dispatch("setSortBy", 'externeObjektNr'); }

            const res = await Immobilie.api().get(`/immobilies?${query ? query : await getQueryString()}`, { save: false, params: { projectId: String(currentProject.value.id) } });

            const resProperties = (res.getDataFromResponse() as any);

            if (!resProperties.results || resProperties.results.length === 0) {
                console.log("Fetched Properties: empty result")
                Immobilie.commit((state) => {
                    state.searchResultsCount = 0;
                    state.totalSearchResultsCount = 0;
                });
                return;
            }

            const fetchedProperties = resProperties.results as Immobilie[];

            const pagination = resProperties.pagination;

            const retrievedCount = pagination.page >= pagination.pageCount ? pagination.total : pagination.page * pagination.pageSize;
            Immobilie.commit((state) => {
                state.searchResultsCount = retrievedCount;
                state.totalSearchResultsCount = pagination.total;
            });

            const propsToPush: Immobilie[] = [];
            for (const prop of fetchedProperties) {
                sanitizePropertyAfterFetch(prop);

                const foundPersisted = persistedProperties?.find((localProp: any) => localProp.id === prop.id);
                if (foundPersisted) {
                    const immobilie = new Immobilie(prop);

                    if (immobilie && foundPersisted.updatedAt < immobilie.updatedAt) {
                        /*
                         * Persisted Property is not up-to-date anymore
                         */

                        immobilie.isDownloaded = true;

                        // console.log("local prop found, replace with newer one", prop, immobilie);
                        // // TODO replace with full
                        // console.warn("TODO - replace with full prop instead of preview prop (local prop found, replace with newer one)")
                        // await Immobilie.dispatch('$updateLocally', { data: immobilie });
                        const immo = await fetchFullProperty(immobilie.id);
                        await downloadImmobilie(immo.id); // todo is this still working?
                    } else {
                        // console.log("persisted prop found", foundPersisted);
                        /**
                         * Persisted property is up-to-date, so push persisted.
                         */
                        propsToPush.push(foundPersisted);
                    }
                } else {
                    /**
                     * No persisted prop found for this id.
                     * But is the property already loaded in vuex? if not, push.
                     */
                    // console.log("no persisted prop found. ", prop);
                    const foundLocal = Immobilie.all().find((localProp: any) => localProp.id === prop.id);
                    if (!foundLocal) {
                        // console.log("pushing property as no local found", prop)
                        propsToPush.push(prop);
                    }
                }
            }

            // TODO: handle edgecase when there are properties persisted on the client database that are not in the server database anymore
            // should not happen by user interaction, but is possible to occur by backend actions e.g. deleting properties
            // the check should maybe happen at application startup, not at every fetch
            await Immobilie.insert({ data: propsToPush });

            // console.log("page count", resProperties.pagination.pageCount);

            //Post status updates
            Immobilie.commit((state) => {
                state.page = pagination.page;
                state.pageCount = pagination.pageCount;
                state.propertiesLoaded = true;
            });

        } catch (error) {
            // On Network Error, load Immobilies from local storage
            if (error.message === "Network Error") {
                const immobilies = await loadPersistedProperties();
                await Immobilie.insert({ data: [...immobilies] });

                Immobilie.commit((state) => {
                    state.page = 1;
                    state.pageCount = 1;
                    state.propertiesLoaded = true;
                 });
            } else {
                console.error(error)
            }
        }
    }

    /**
     * Checks wether a property is downloaded or not.
     */
    const isPropertyDownloaded = async (propId: number) => {
        await loadPersistedProperties();
        const propsDownloaded = Immobilie.getters("persistedProperties")
        const im = propsDownloaded.find((el: any) => el.id === propId);
        if (!im) return false;
        return im.isDownloaded;
    }

    /**
     * Load full immobilie
     */
    const fetchFullProperty = async (id: any) => {
        try {
            const wasDownloaded = isPropertyDownloaded(id);
            const res = await Immobilie.api().get(`/immobilies/${id}`, { save: false, params: { projectId: currentProject.value.id } })
            const immo = (res.getDataFromResponse() as any).data;
            sanitizePropertyAfterFetch(immo);
            await Immobilie.insertOrUpdate({ data: immo });

            if (wasDownloaded) { // TODO: refactor, it is always truthy, as isPropertyDownloaded is called without await.
                await downloadImmobilie(id);
            }
            return immo;
        } catch (error: any) {
            if (error.message === "Network Error") {
                console.log("Cannot load full immobilie, no connection!");
            } else {
                throw error;
            }
        }
    }

    /**
     * Todo refactor
     * at the moment this will never be executed as we send immobilies with our ba's.
     */
    const considerDownloadImmobilie = async (immobilieId?: number) => {
        if (!immobilieId) {
            console.error('Cannot download Property of Ba as property is undefined.')
            return;
        }

        let immobilie: Immobilie | undefined = Immobilie.find(immobilieId) as (Immobilie | undefined);

        if (!immobilie) {
            await fetchFullProperty(immobilieId);
            immobilie = Immobilie.find(immobilieId) as (Immobilie | undefined);
        }

        if (!immobilie) {
            console.error('Could not find and download immobilie with id', immobilieId);
            return;
        }

        await downloadImmobilie(immobilie.id);
    }

    /**
     * Check if there are some ba's left that are offline. If not, remove from downloaded.
     */
    const considerRemoveImmobilieFromDownloaded = async (immobilieId: number) => {
        const bas = BestandsaufnahmeModel.query().where("immobilie", immobilieId).all();

        const localBas = bas.filter(ba => ba.isDownloaded || ba.isLocal);

        if (!localBas || localBas?.length == 0) {  // delete Immobilie only if no other downloaded BA has same Immobilie
            const im = Immobilie.find(immobilieId);
            if (!im) {
                console.warn("considerRemoveImmobilieFromDownloaded: Could not find immobilie", immobilieId);

                return;
            }

            im!.isDownloaded = false;

            // await Immobilie.dispatch('removeFromPersistedProperties', im);
            await Immobilie.dispatch('$deleteFromLocal', im.id);
            await Immobilie.insertOrUpdate({ data: im as any });
        }
    }

    /**
     * Are properties loaded yet?
     */
    const propertiesLoaded = computed(() => {
        return Immobilie.getters("loaded") || (properties.value && properties.value.length > 0) }
    );


    const getImmoPhototask = ( property: Immobilie, type: string, image: APhoto ): APhotoUploadTask => {

        return {
            field: type,
            image,
            ref: type,
            api: "api::immobilie.immobilie",
            uid: immoPhotoUid,
            status: instanceOfPhoto(property.vorschaubild) ? "preparing" : "success",
            instanceId: property.id,
            fileName: property.id + Date.now().toString() + ".jpeg"
        }
    }

    const createVorschauBildTasks = async () => {
        const persistedProperties = await loadPersistedProperties();
        const vorschauBildTasks: APhotoUploadTask[] = [];
        const bildTasks: APhotoUploadTask[] = [];
        const updatedPropertyIds: number[] = [];

        persistedProperties.forEach((property: Immobilie) => {
            // @ts-ignore
            if ( property.vorschaubild && property.vorschaubild.webPath ) {
                vorschauBildTasks.push( getImmoPhototask( property, "vorschaubild", property.vorschaubild ) );
                updatedPropertyIds.push(property.id);
            } 
            if ( property.bilder && property.bilder.length && property.bilder.length > 0 ) {
                property.bilder.forEach((bild: any) => {
                    if ( bild.webPath) {
                        bildTasks.push( getImmoPhototask( property, "bilder", bild ) );
                        
                        const existingPersistedBaIndex = updatedPropertyIds.findIndex(( id: number ) => id === property.id );

                        if ( existingPersistedBaIndex === -1 ) {
                            updatedPropertyIds.push( property.id );
                        }
                    }
                });
            
            }
        });

        return {
            vorschauBildTasks,
            bildTasks,
            updatedPropertyIds
        }
    }

    const sendImmophotos = async ( tasks: APhotoUploadTask[] ) => {
        return await Promise.all(
            tasks.map((el: APhotoUploadTask) => {
                return uploadPhoto(
                    // @ts-ignore
                    el.image,
                    el.api,
                    el.instanceId?.toString(),
                    el.field,
                    el.fileName,
                    "immobilie"
                    );
            })
        );
    }
    const sendPersistedImages = async () => {
        const {
            vorschauBildTasks,
            bildTasks,
            updatedPropertyIds
        } = await createVorschauBildTasks();
        let uploadVorschauBildResults: any = [];
        
        try {
            uploadVorschauBildResults  = await sendImmophotos( vorschauBildTasks );

        } catch (error) {
            console.error("sendImmophotos vorschaubild error", error);
            throw error;

        }

        try {
            await sendImmophotos( bildTasks );

        } catch (error) {
            console.error("sendImmophotos bilder error", error);
            throw error;
        }

        try {
            await Promise.all(
                uploadVorschauBildResults.map((el: any, index: number) => {
                    return Immobilie.api().post("/immobilie/addImmoPhoto", {
                        propertyId: vorschauBildTasks[index].instanceId?.toString(),
                        imageId: el.data[0].id
                    });
                })
            );
        } catch (error) {
            console.error("addImmoPhoto error", error);
            throw error;
        }
       
        try {
            await Promise.all(
                updatedPropertyIds.map((id: number) => {
                    return fetchFullProperty(id);
                    
                })
            );
        }
        catch (error) {
            console.error("fetchFullProperty error", error);
            throw error;
        }
       
    }

    const changePerson = async (property: Immobilie, t: any) => {
        if(!featureFlags?.value.property?.changePerson) return
        const response = (await apiClient.get(`${strapiApiUrl}/users?filters[organisation][id][$eq]=${user.value!.organisation.id}`)).data
        const users = response.map((item: any) => ({
            label: item.username,
            type: 'radio',
            value: item.id,
            checked: property.verwalters.filter((propertyItem: any) => propertyItem.username === item.username).length > 0
        }))

        const alertButtons = [
            {
            text: t('immobilie.buttons.confirm'),
            handler: async (userId: string) => {
                await Immobilie.api().put(`/immobilies/${property.id}?projectId=${currentProject.value.id}`, {
                save: true,
                data: {
                    verwalters: {
                    disconnect: property.verwalters.map((item: any) => { return { id: item.id }}),
                    connect: [
                        { id: userId }
                    ]
                    }
                }
                })
                fetchFullProperty(property.id);
            }
            },
            t('immobilie.buttons.cancel')
        ];

        const alert = await alertController.create({
            header: t('immobilie.updatePerson'),
            buttons: alertButtons,
            //@ts-ignore
            inputs: users
        })

        await alert.present();
    }

    const changeStatus = async (property: Immobilie, t: any) => {
        if(!featureFlags?.value.property?.changeStatus) return
        const alertButtons = [
          {
            text: t('immobilie.buttons.confirm'),
              handler: async (status: ImmobilieStatus) => {
                await Immobilie.api().put(`/immobilies/${property.id}?populate=verwalters&projectId=${currentProject.value.id}`, {
                  save: true,
                  data: { status: status }
                })
                fetchFullProperty(property.id);
              }
          },
          t('immobilie.buttons.cancel')
        ];
        const statuses = [
          {
            label: ImmobilieStatus.ABGESCHLOSSEN,
            type: 'radio',
            value: ImmobilieStatus.ABGESCHLOSSEN,
            checked: property.status === ImmobilieStatus.ABGESCHLOSSEN
          },
          {
            label: ImmobilieStatus.ANGELEGT,
            type: 'radio',
            value: ImmobilieStatus.ANGELEGT,
            checked: property.status === ImmobilieStatus.ANGELEGT
          },
          {
            label: ImmobilieStatus.ARCHIVIERT,
            type: 'radio',
            value: ImmobilieStatus.ARCHIVIERT,
            checked: property.status === ImmobilieStatus.ARCHIVIERT
          },
          {
            label: ImmobilieStatus.FREIGEGEBEN,
            type: 'radio',
            value: ImmobilieStatus.FREIGEGEBEN,
            checked: property.status === ImmobilieStatus.FREIGEGEBEN
          },
          {
            label: ImmobilieStatus.GEPLANT,
            type: 'radio',
            value: ImmobilieStatus.GEPLANT,
            checked: property.status === ImmobilieStatus.GEPLANT
          },
          {
            label: ImmobilieStatus.IN_DURCHFUEHRUNG,
            type: 'radio',
            value: ImmobilieStatus.IN_DURCHFUEHRUNG,
            checked: property.status === ImmobilieStatus.IN_DURCHFUEHRUNG
          },
        ];
        const alert = await alertController.create({
          header: t('immobilie.updateStatus'),
          buttons: alertButtons,
          //@ts-ignore
          inputs: statuses
        })
        await alert.present();
    }

    const loadAllProperties = async () => {
        Immobilie.dispatch("setSearchTerm", "");
        const apiQuery: any = {
            pagination: {
                page: 1,
                pageSize: 10000000000
            }
            };
    
        const query = QueryString.stringify(apiQuery)
        await loadImmobilienPreviews(query)
    }

    const uploadPropertyVorschaubild = async (property: any, images: Array<any>) => {
        const data = property
        data.attributes["id"] = property.id
        const { uploadVorschaubild } = useImmobilieHelper(data.attributes as Immobilie );
        await uploadVorschaubild(images[0], property.id )
        return
    }

    const generatePropertyShareLink = (propertyId: string) => {
        const serverUrl = environment.SERVER_URL
        const url = serverUrl + `/property/${propertyId}`

        return url
    }

    const redirectToMap = async ({ lon, lat }: { lon: number, lat: number }) => {
        const googleUrl = `comgooglemaps://?q=${lat},${lon}&zoom=14&views=traffic`;
        const appleUrl = `https://maps.apple.com/?q=${lat},${lon}&z=14`;
        if ( lat && lon) {
          if ( isMobile.value ) {
            try {
              const isGoogleMapsAvailable = await AppLauncher.canOpenUrl({ url: googleUrl });
              const isAppleMapsAvailable = await AppLauncher.canOpenUrl({ url: appleUrl });
              if ( isGoogleMapsAvailable.value ) {  
                await AppLauncher.openUrl( { url: googleUrl } );
              } else if ( isAppleMapsAvailable.value ) {
                await AppLauncher.openUrl( { url: appleUrl } );
              } else {
                //TODO: send to sentry
                console.log("Can't use neither Google Maps nor Apple Maps");
              }
            } catch (error) {
              //TODO: send to sentry
              console.log('Error opening map on mobile:', error);  
            }
          } else if ( networkConnected.value ) {
            try {
              await AppLauncher.openUrl( { url: `https://maps.google.com/?q=@${lat},${lon}` } );
            } catch (error) {
              //TODO: send to sentry
              console.log('Error opening map on desktop:', error); 
            }
          }
        }
    };

    const handlePropertyShare = async ( propertyId: string, t: any ) => {
        const url = generatePropertyShareLink(propertyId)
  
        if(store.state.app.appPlatform === AppPlatform.desktop) {
          await Clipboard.write({
            string: url
          })
  
          const toast = await toastController.create({
              message: t('property.urlCopied'),
              duration: 3000,
              cssClass: 'copyUrlToast'
          })
  
          await toast.present();
        } else {
          await Share.share({
            title: t('property.shareProperty.title'),
            text: t('property.shareProperty.text'),
            url: url
          });
        }
      };

    return {
        properties,
        loadImmobilienPreviews,
        considerRemoveImmobilieFromDownloaded,
        considerDownloadImmobilie,
        downloadImmobilie,
        fetchFullProperty,
        propertiesLoaded,
        supportedSortTerms,
        sendPersistedImages,
        changePerson,
        changeStatus,
        loadAllProperties,
        uploadPropertyVorschaubild,
        generatePropertyShareLink,
        redirectToMap,
        handlePropertyShare
    };
}








